// Colors
//$primary:           $esun_primary;
//$secondary:         $esun_secondary;
//
//$body-bg:           $esun_bg__light;
//$body-color:        $esun_text__light;
//

// Typography
//$font-family-body:  "Bitter", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-base:  $font-family-body;
//
//$headings-font-family:        "Montserrat", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$headings-font-weight:        600;

// Breakpoints
// Extra small screen / phone / 600
$xs:                  0px;
// Small screen / tablet / 900
$sm:                  576px;
// Medium screen / laptop / 1200 / MacBook Air 11": 1366 x 768
$md:                  768px;
// Large screen / desktop / 1800 / 21" is HD 1920 x 1080
$lg:                  992px;
// Large screen / desktop / 1800 / 21" is HD 1920 x 1080
$xl:                  1200px;
// HD screen / desktop / 1920 / 21" is HD 1920 x 1080
$hd:                  1920px;
// 27" screen / desktop / 2560 / iMac 27" is HD 2560 x 1440
$hd2:                 2560px;
// 4k screen / desktop / 3840 / 4k is HD 3840 x 2160
$hd4:                 3840px;

// Bootstrap 4
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);
